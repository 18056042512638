/**
 * Theme toggle functionality for switching between dark, light, and classic modes
 */

export function initializeThemeToggle() {
    // Theme toggle functionality
    const htmlElement = document.documentElement;
    
    // Theme option buttons
    const themeDarkBtn = document.querySelector('.js-theme-dark');
    const themeLightBtn = document.querySelector('.js-theme-light');
    const themeClassicBtn = document.querySelector('.js-theme-classic');
    
    // Theme constants
    const THEME_MODE = {
        DARK: 'dark',
        LIGHT: 'light',
        CLASSIC: 'classic'
    };
    
    // Cookie name constant
    const THEME_COOKIE_NAME = 'shinobi-theme-mode';
    
    // Function to set a cookie with a given name, value and expiration days
    function setCookie(name, value, days = 365) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
        const expires = "; expires=" + date.toUTCString();
        document.cookie = name + "=" + (value || "") + expires + "; path=/; SameSite=Lax";
    }
    
    // Function to get a cookie by name
    function getCookie(name) {
        const nameEQ = name + "=";
        const ca = document.cookie.split(';');
        for (let i = 0; i < ca.length; i++) {
            let c = ca[i];
            while (c.charAt(0) === ' ') c = c.substring(1, c.length);
            if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
        }
        return null;
    }
    
    // Function to apply theme based on current settings
    function applyTheme(themeMode) {
        // Remove all theme classes first
        htmlElement.classList.remove('dark-mode', 'classic');
        
        // Store current theme mode in cookie
        setCookie(THEME_COOKIE_NAME, themeMode);
        
        // Apply appropriate theme based on mode
        switch(themeMode) {
            case THEME_MODE.DARK:
                htmlElement.classList.add('dark-mode');
                break;
                
            case THEME_MODE.CLASSIC:
                htmlElement.classList.add('classic');
                break;
                
            // Default to light theme
            case THEME_MODE.LIGHT:
            default:
                // No additional classes needed for light theme
                break;
        }
        
        // Update active state on buttons
        updateActiveButtonState(themeMode);
        
        // Notify iframes about the theme change
        notifyIframesThemeChanged(themeMode);
    }
    
    // Function to update active state on theme buttons
    function updateActiveButtonState(activeTheme) {
        // Remove active class from all buttons
        [themeDarkBtn, themeLightBtn, themeClassicBtn].forEach(btn => {
            if (btn) btn.classList.remove('active');
        });
        
        // Add active class to current theme button
        switch(activeTheme) {
            case THEME_MODE.DARK:
                if (themeDarkBtn) themeDarkBtn.classList.add('active');
                break;
            case THEME_MODE.LIGHT:
                if (themeLightBtn) themeLightBtn.classList.add('active');
                break;
            case THEME_MODE.CLASSIC:
                if (themeClassicBtn) themeClassicBtn.classList.add('active');
                break;
        }
    }
    
    // Function to notify all iframes about theme change
    function notifyIframesThemeChanged(themeMode) {
        // Find all iframes in the document
        const iframes = document.querySelectorAll('iframe');
        
        // Send message to each iframe
        iframes.forEach(iframe => {
            try {
                if (iframe.contentWindow) {
                    iframe.contentWindow.postMessage({ 
                        type: 'theme-changed', 
                        theme: themeMode 
                    }, '*');
                }
            } catch (e) {
                console.error('Failed to notify iframe about theme change:', e);
            }
        });
    }
    
    // Initialize theme based on stored preference or default to dark mode
    let storedTheme = getCookie(THEME_COOKIE_NAME);
    
    // Default to dark mode if no theme is stored
    if (!storedTheme) {
        storedTheme = THEME_MODE.DARK;
    }
    
    // Apply the theme
    applyTheme(storedTheme);
    
    // Set up event listeners for theme buttons
    if (themeDarkBtn) {
        themeDarkBtn.addEventListener('click', function(e) {
            e.preventDefault();
            applyTheme(THEME_MODE.DARK);
        });
    }
    
    if (themeLightBtn) {
        themeLightBtn.addEventListener('click', function(e) {
            e.preventDefault();
            applyTheme(THEME_MODE.LIGHT);
        });
    }
    
    if (themeClassicBtn) {
        themeClassicBtn.addEventListener('click', function(e) {
            e.preventDefault();
            applyTheme(THEME_MODE.CLASSIC);
        });
    }
    
    // Handle legacy dark mode setting for backward compatibility
    const legacyDarkMode = localStorage.getItem('shinobi-dark-mode');
    if (legacyDarkMode !== null) {
        // Migrate legacy setting to new format
        applyTheme(legacyDarkMode === 'true' ? THEME_MODE.DARK : THEME_MODE.LIGHT);
        // Remove legacy setting
        localStorage.removeItem('shinobi-dark-mode');
    }
} 